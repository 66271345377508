.register-page {
    grid-area: m1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 90vw;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.container {
    background-color: var(--Gray-0);
    height: max-content;
    margin: 3rem 0;
    padding: 1rem;
    box-shadow: 0 0 0.5rem var(--boxShadow);
}

.register-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    gap: 0.5rem;
}

.closeX {
    position: absolute;
    top: 0;
    right: 0;
    color: var(--Gray-0);
    background-color: var(--Red-9);
    padding: 0.1rem 0.4rem;
    border-radius: 0.3rem;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
}

.closeX:hover {
    box-shadow: 0 0 5px var(--Red-6);
    cursor: pointer;
    color: var(--Gray-0);
}

.register-in {
    height: 2.5rem;
    background-color: var(--Gray-1);
    border: 1px solid var(--Gray-6);
    border-radius: 5px;
    padding: 0 0.5rem;
    width: 20rem;
}

.login-page {
    grid-area: m1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 100%;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

/* BASIC SETUP */
.Toastify{
    position: absolute;
}
.Toastify__toast-container {
    margin-top: 8.5rem;
    z-index: 100;
}
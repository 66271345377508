

/* type scale: 9px 12px 15px 18px 23px 28px 36px 44px 55px  */
/*--------------------------------------------------------------*/
/* BASIC SETUP */

*:focus {
  outline: none;
  outline-width: 0;
}

* {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
}

.team-box {
  grid-area: m1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 10rem;
  margin-top: auto;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.team-box > div:nth-child(1) {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--Gray-9);

}

.team-member{
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 0.5rem var(--boxShadow);
}

.team-member > div:nth-child(1) {
  align-self: self-start;
  margin-bottom: auto;
  color: var(--Gray-9);
  padding: 2rem;
  padding-bottom: 0;
}

.team-member>div:nth-child(2) {
  padding: 2rem;
  padding-top: 1rem;
}

.team-member>div:nth-child(2)>img{
  float: left;
  margin-right: 2rem;
  width: 15rem;
  height: auto;
}

.team-member {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--Gray-0);
  margin-bottom: 0.2rem;
}




/* Next & previous buttons */
.prev,
.next {
  cursor: pointer;
  width: auto;
  padding: 24px 8px;
  color: var(--Gray-1);
  font-weight: bold;
  font-size: 1.2rem;
  transition: 0.6s ease;
}

.prev {
  border-radius: 16px 0 0 16px;
}

.next {
  border-radius: 0 16px 16px 0;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover,
.next:hover {
  background-color: var(--Gray-3);
  color: var(--Gray-9);
  box-shadow: 0 0 5px var(--Gray-9);
}


/* The dots/bullets/indicators */
.dot, .active {
  cursor: pointer;
  height: 0.3rem;
  width: 1.4rem;
  margin: 0 0.5rem;
  background-color: var(--Gray-3);
  border-radius: 20%;
  display: inline-block;
  transition: background-color 0.3s ease;
  box-shadow: 0 0 2px var(--Gray-0);
}

.active,
.dot:hover {
  background-color: var(--Blue-8);
}

/* Fading animation */
.fade {
  animation-name: fade;
  animation-duration: 1.5s;
}

@keyframes fade {
  from {
    opacity: .4
  }

  to {
    opacity: 1
  }
}

/* ------------------------------------------------------------------------ */
@media (max-width: 1600px) {
  .team-box {
    padding: 0 6rem;
  }
}

@media (max-width: 1472px) {
  .team-box {
    padding: 0 3rem;
  }
}

@media (max-width: 1380px) {
  .team-box {
    padding: 0 0.5rem;
  }
}

@media (max-width: 1250px) {
  .team-member>div:nth-child(1) {
    padding: 1rem;
    padding-bottom: 0;
  }

  .team-member>div:nth-child(2) {
    padding: 1rem;
  }

  .team-member>div:nth-child(2)>img {
    margin-right: 1rem;
  }
}

@media (max-width: 550px) {
  .team-member>div:nth-child(2) {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    padding-top: 1rem;
  }
}



/* type scale: 9px 12px 15px 18px 23px 28px 36px 44px 55px  */
/*--------------------------------------------------------------*/
/* BASIC SETUP */

*:focus {
  outline: none;
  outline-width: 0;
}

* {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
}

.portfolio {
  grid-area: m1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem 4rem;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  margin-top: auto;
}

.portfolio > div:nth-child(1) {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--Gray-9);
}

.port-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* background-color: var(--Green-6); */
  background-color: var(--Blue-5);
  margin-bottom: 0.2rem;
  box-shadow: 0 0 0.5rem var(--boxShadow);
}

.port-item > div {
  margin-bottom: auto;
  color: var(--Gray-0);
  padding: 5rem 1rem;
}

.port-item img {
  width: 75%;
  height: auto;
}


/* Next & previous buttons */
.prev,
.next {
  cursor: pointer;
  width: auto;
  padding: 24px 8px;
  color: var(--Gray-1);
  font-weight: bold;
  font-size: 1.2rem;
  transition: 0.6s ease;
}

.prev {
  border-radius: 16px 0 0 16px;
}

.next {
  border-radius: 0 16px 16px 0;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover,
.next:hover {
  background-color: var(--Gray-3);
  color: var(--Gray-9);
  box-shadow: 0 0 5px var(--Gray-9);
}


/* The dots/bullets/indicators */
.dot, .active {
  cursor: pointer;
  height: 0.3rem;
  width: 1.4rem;
  margin: 0 0.5rem;
  background-color: var(--Gray-3);
  border-radius: 20%;
  display: inline-block;
  transition: background-color 0.3s ease;
  box-shadow: 0 0 2px var(--Gray-0);
}

.active,
.dot:hover {
  background-color: var(--Blue-8);
}

/* Fading animation */
/* .fade {
  animation-name: fade;
  animation-duration: 1.5s;
}

@keyframes fade {
  from {
    opacity: .4
  }

  to {
    opacity: 1
  }
} */

/* ------------------------------------------------------------------------ */
@media (max-width: 1200px) {
  .port-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .port-item>div {
    height: fit-content;
    padding: 1rem;
    align-self: flex-start;
  }

  .port-item img {
    width: 100%;
  }
}


/* ------------------------------------------------------------------------ */
@media (max-width: 725px) {
  .portfolio {
    padding: 0;
    transition: all 0.2s ease-in-out;
  }
}


/* type scale: 9px 12px 15px 18px 23px 28px 36px 44px 55px  */
/*--------------------------------------------------------------*/
/* BASIC SETUP */

*:focus {
  outline: none;
  outline-width: 0;
}



.about-page {
  grid-area: m1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: auto;
  padding: 1rem 10rem;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.about-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--Gray-0);
  padding: 2rem;
  margin-bottom: 0.2rem;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 0 0.5rem var(--boxShadow);
}

.about-box>img {
  width: 100%;
  padding-bottom: 0.5rem;
}


/* ------------------------------------------------------------------------ */
@media (max-width: 1600px) {
  .about-page {
    padding: 0 5rem;
    transition: all 0.3s ease-in-out;
  }
}

@media (max-width: 1080px) {
  .about-page {
    padding: 0 1rem;
    transition: all 0.3s ease-in-out;
  }
}